import React from "react";
import styled from "styled-components";

const Loader = () => (
  <div className="text-center">
    <SpinnerBorder className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </SpinnerBorder>
  </div>
);

export default Loader;

const SpinnerBorder = styled.div`
  width: 3rem;
  height: 3rem;
`;
