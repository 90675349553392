import React, { useCallback, useRef, useLayoutEffect, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Loader from "./Components/Loader";
import "../src/css/main.css";

const Header = React.lazy(() => import("./Components/Header"));
const AboutSection = React.lazy(() => import("./Components/AboutSection"));
const Footer = React.lazy(() => import("./Components/Footer"));
const AutoShop = React.lazy(() => import("./Components/AutoShop"));
const Partners = React.lazy(() => import("./Components/Partners"));
const Specialisation = React.lazy(() => import("./Components/Specialisation"));
const NavBar = React.lazy(() => import("./Components/NavBar"));

const headings = [
  ["Имате проблем?", "Ние Имаме Решение."],
  ["За Нас", "Нашата Дейност и Приоритети"],
  ["Сервиз - Информация"],
  ["Галерия"],
];

export const App = () => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }, [messagesEndRef]);

  const messagesTopRef = useRef(null);

  const scrollToTop = useCallback(() => {
    messagesTopRef.current.scrollIntoView({ behavior: "smooth" });
  }, [messagesTopRef]);

  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div ref={messagesTopRef}>
      <Suspense fallback={<Loader />}>
        <NavBar scrollToBottom={scrollToBottom} />
      </Suspense>

      <Routes>
        <Route
          exact
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <Header
                logo="./img/tehso-logo.png"
                headings={headings[0]}
                h1Header="Автосервиз Tehso Пловдив"
              />
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense fallback={<Loader />}>
              <Header logo="./img/tehso-logo.png" headings={headings[1]} />
            </Suspense>
          }
        />
        <Route
          path="/shop"
          element={
            <Suspense fallback={<Loader />}>
              <Header logo="./img/tehso-logo.png" headings={headings[2]} />
            </Suspense>
          }
        />
        <Route
          path="/gallery"
          element={
            <Suspense fallback={<Loader />}>
              <Header logo="./img/tehso-logo.png" headings={headings[3]} />
            </Suspense>
          }
        />
        <Route path="/partners" element={<></>} />
      </Routes>

      <Routes>
        <Route
          exact
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <Specialisation />
            </Suspense>
          }
        />
        <Route
          path="/partners"
          element={
            <Suspense fallback={<Loader />}>
              <Partners />
            </Suspense>
          }
        />
        <Route
          path="/shop"
          element={
            <Suspense fallback={<Loader />}>
              <AutoShop scrollToTop={scrollToTop} />
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense fallback={<Loader />}>
              <AboutSection />
            </Suspense>
          }
        />
      </Routes>
      <Suspense fallback={<Loader />}>
        <Footer messagesEndRef={messagesEndRef} scrollToTop={scrollToTop} />
      </Suspense>
    </div>
  );
};
